import React, {useState, useEffect, useRef} from 'react';
import { apiPost, apiGet, getBenefitsApiUrl, getAdminApiUrl, apiMessages, getAccountsApiUrl } from '../common/AppApi';
import { invalidInputStyle, selectOptions, patternMessage } from '../common/Forms';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import useUserContext from '../common/UserContext';

import { SelectOneArray } from '../common/Constants';
import AlertDialog, {showAlertDialog} from '../common/AlertDialog';
import { BenefitTypeArray, PrivilegeCodes } from '../common/Constants';

import DatePicker from 'react-date-picker';
import moment from 'moment';


export default function AddBenefitForm(props) {
    let {user} = useUserContext();

	let blankForm = {
		benefitRecipientAccountIdentifier:'',
		benefitAuthorizationNumber:'',
		benefitAvailableDate:'',
		benefitExpirationDate:'',
		benefitAmount:'',
		programUuid:'',
		fundingId:'',
		benefitTypeId:1,
		incentiveUuid:'',
		creationChannel:'API',
		agencyUuid:'',
		note:'',
		benefitOptionId:''
	};

	let maxDate = new Date();
	maxDate.setDate(new Date().getDate() + 366);

	let [benefit, setBenefit ] = useState(blankForm);

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [programs, setPrograms] = useState([]);
	let [fundingSources, setFundingSources] = useState([]);
	let [formChanged, setFormChanged] = useState(false);
	let [incentives, setIncentives] = useState([]);
	let [endDateDisabled, setEndDateDisabled] = useState(false);
	let blankExpirations = {
		benefitExpiration:0,
		incentiveExpiration:0,
		benefitAmount:0,
		enableBenefitNote:false,
		benefitOptionTypeId:'',
		lastIssuanceDate:''};
	let [expirationDays, setExpirationDays] = useState(blankExpirations);
		let [benefitAmountDisabled, setBenefitAmountDisabled] = useState(false);
	let [benefitAmount, setBenefitAmount] = useState('');
	let [benefitOptionType, setBenefitOptionType] = useState({name:'',options:[]});

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setBenefit({...benefit, [id]:value});
		setFormChanged(true);
	}

	let handleDayChange = (name, val) => {
		let cloned = {...benefit, [name]:val};
		if (endDateDisabled && name == 'benefitAvailableDate') {
			let daysToAdd = 0;
			if (benefit.benefitTypeId == 1 && expirationDays.benefitExpiration > 0) {
				daysToAdd = expirationDays.benefitExpiration;
			} else if (expirationDays.incentiveExpiration > 0) {
				daysToAdd = expirationDays.incentiveExpiration;
			}
			daysToAdd--; // subtract one day to get inclusive range
			cloned.benefitExpirationDate = new Date(cloned.benefitAvailableDate);
			cloned.benefitExpirationDate.setDate(cloned.benefitAvailableDate.getDate() + daysToAdd);
		}
		setBenefit(cloned);
		setFormChanged(true);
	}

	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/account/programs';
		if (user.privileges.includes(PrivilegeCodes.adminAccess))
			url = getAdminApiUrl() + '/programs';

		setSpinner(true);
		apiGet(url).then((resp) => {
			//console.debug(resp);
			let programs = [];
			resp.forEach( (p) => {
				programs.push({id:p.programUuid,
					text:p.programName,
					benefitExpiration:p.benefitExpiration,
					incentiveExpiration:p.incentiveExpiration,
					benefitAmount:p.benefitAmount,
					enableBenefitNote:p.enableBenefitNote ? true : false,
					benefitOptionTypeId:p.benefitOptionTypeId,
					lastIssuanceDate:p.lastIssuanceDate});
			});
			setPrograms(SelectOneArray.concat(programs));
			if (programs.length == 1) {
				document.getElementById('programUuid').value = programs[0].id;
				setBenefit({...benefit, programUuid:programs[0].id});
			}
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [agencies, setAgencies] = useState([]);
	let getAgencies = () => {
		let url = getAccountsApiUrl() + '/agency';
		setSpinner(true);
		apiGet(url).then((resp) => {
			let agencies = [];
			agencies.push({id:'',text:''});
			resp.forEach( (a) => {
				agencies.push({id:a.agencyUuid, text:a.agencyName});
			});
			setAgencies(agencies);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let agencyRequired = () => {
		return user.privileges.includes(PrivilegeCodes.agencyAdminAccess) || user.privileges.includes(PrivilegeCodes.agencyUserAccess);
	};

	let checkForEnddate = () => {
		if (benefit.benefitTypeId == 1 && expirationDays.benefitExpiration > 0) {
			setEndDateDisabled(true);
		} else if (expirationDays.incentiveExpiration > 0) {
			setEndDateDisabled(true);
		} else {
			setEndDateDisabled(false);
		}

		let result = recalculateEndDate();
		let result2 = checkBenefitType();

		if (result.changed && result2.changed) {
			setBenefit({...result.clone, benefitAuthorizationNumber:result2.clone.benefitAuthorizationNumber});
		} else if (result.changed) {
			setBenefit(result.clone);
		} else if (result2.changed) {
			setBenefit(result2.clone);
		}
	};

	let recalculateEndDate = () => {
		let cloned = {...benefit};
		let stuffChanged = false;
		if (expirationDays.benefitAmount && benefit.benefitTypeId == 1) {
			if (expirationDays.benefitAmount != benefit.benefitAmount) {
				cloned.benefitAmount = expirationDays.benefitAmount;
				stuffChanged = true;
			}
			setBenefitAmountDisabled(true);
		} else {
			setBenefitAmountDisabled(false);
		}

		if (benefit.benefitAvailableDate > '') {
			let daysToAdd = 0;
			if (benefit.benefitTypeId == 1 && expirationDays.benefitExpiration > 0) {
				daysToAdd = expirationDays.benefitExpiration;
			} else if (expirationDays.incentiveExpiration > 0) {
				daysToAdd = expirationDays.incentiveExpiration;
			}

			if (daysToAdd > 0) {
				daysToAdd--; // subtract one day to get inclusive range
				cloned.benefitExpirationDate = new Date(cloned.benefitAvailableDate);
				cloned.benefitExpirationDate.setDate(cloned.benefitAvailableDate.getDate() + daysToAdd);
				stuffChanged = true;
			}
		}

		if (stuffChanged) {
			return {changed:stuffChanged, clone:cloned};
		}
		return {changed:false};
	};

	let getBenefitOptionType = (id) => {
		let url = getBenefitsApiUrl() + '/benefit-option-type/' + id;
		//console.debug(url);
		setSpinner(true);
		apiGet(url).then((resp) => {
			//console.debug(resp);
			let b = {name:resp.benefitOptionType.name,options:[{optionId:'',descr:''}]};
			resp.options.forEach(item => {
				b.options.push(item);
			});
			setBenefitOptionType(b);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		if (benefit.programUuid) {
			getFundingSources(benefit.programUuid);
			getIncentives(benefit.programUuid);
		} else {
			setFundingSources([]);
			setIncentives([]);
		}

		programChanged(benefit.programUuid);
	}, [benefit.programUuid]);

	useEffect( () => {
		checkForEnddate();
	}, [expirationDays]);

	useEffect( () => {
		patternMessage("#benefitAuthorizationNumber", Strings.validation.authNoFormat);
	}, []);

	let checkBenefitType = () => {
		let cloned = {...benefit};
		if (benefit.benefitTypeId == 2) {
			if (benefit.benefitAuthorizationNumber != 'AUTO-GENERATED')
				cloned.benefitAuthorizationNumber = 'AUTO-GENERATED';
		} else {
			if (benefit.benefitAuthorizationNumber == 'AUTO-GENERATED')
				cloned.benefitAuthorizationNumber = '';
		}

		if (cloned.benefitAuthorizationNumber != benefit.benefitAuthorizationNumber) {
			return {changed:true, clone:cloned};
		}
		return {changed:false};
	};

	useEffect( () => {
		checkForEnddate();
	}, [benefit.benefitTypeId]);

	let programChanged = (id) => {
		let newValue = blankExpirations;
		for (var i = 0; i < programs.length; i++) {
			//console.debug(programs[i]);
			if (programs[i].id == id) {
				if (programs[i].benefitExpiration && programs[i].benefitExpiration > 0)
					newValue.benefitExpiration = programs[i].benefitExpiration;
				if (programs[i].incentiveExpiration && programs[i].incentiveExpiration > 0)
					newValue.incentiveExpiration = programs[i].incentiveExpiration;
				if (programs[i].benefitAmount)
					newValue.benefitAmount = Number(programs[i].benefitAmount).toFixed(2);
				if (programs[i].enableBenefitNote)
					newValue.enableBenefitNote = true;
				if (programs[i].benefitOptionTypeId)
					newValue.benefitOptionTypeId = programs[i].benefitOptionTypeId;
				if (programs[i].lastIssuanceDate){
					newValue.lastIssuanceDate = moment(programs[i].lastIssuanceDate).toDate();
					newValue.lastIssuanceDateDisplay = programs[i].lastIssuanceDate;
				}
				break;
			}
		}
		//console.debug(newValue);
		setExpirationDays(newValue);

		if (newValue.benefitOptionTypeId)
			getBenefitOptionType(newValue.benefitOptionTypeId);
	};

	let benefitRef = useRef(benefit);
	useEffect( () => {
		benefitRef.current = benefit;
	}, [benefit]);

	let getFundingSources = (uuid) => {
		setSpinner(true);
		let url = getBenefitsApiUrl() + '/funding-sources/' + uuid;

		apiGet(url).then((resp) => {
			if (resp && resp.length) {
				let newResp = resp.map( (item) => {
					item.id = item.fundingId;
					item.text = item.name;
					return item;
				});
				setFundingSources(SelectOneArray.concat(newResp));
				if (newResp.length == 1) {
					let selectInput = document.getElementById('fundingId');
					selectInput.value = newResp[0].id;
					setBenefit({...benefitRef.current, fundingId:newResp[0].id});
				}
			}
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if (err.status == 404) {
				setFundingSources([]);
				messageObject.text = 'No funding sources defined for the program.';
			} else if(err.privilegeError) {
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let getIncentives = (programUuid) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/incentive?programUuid=' + programUuid;
		apiGet(url).then((resp) => {
			if(resp && resp.length) {
				let newResp = resp.map( (item) => {
					item.id = item.incentiveUuid;
					item.text = item.name;
					return item;
				});
				setIncentives(SelectOneArray.concat(newResp));
			}
		}).catch( (err) => {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	let [alertSettings, setAlertSettings] = useState({body:'',type:'danger',title:'Last Issuance Date'});

	let handleIssue = (e) => {
		e.preventDefault();

		// check last issuance date
		if (expirationDays.lastIssuanceDate && benefit.benefitAvailableDate > expirationDays.lastIssuanceDate) {
			setAlertSettings({...alertSettings, body:'The Last Issuance Date is '
				 + moment(expirationDays.lastIssuanceDateDisplay).format("YYYY-MM-DD") + '.'});
			showAlertDialog();
			return;
		}

		setSpinner(true);

		let body = {...benefit};
		body.benefitProgramIdentifier = benefit.programUuid;
		body.benefitAmount = Number(benefit.benefitAmount);

		// avoid time zone issues
		body.benefitAvailableDate.setHours(12);
		body.benefitExpirationDate.setHours(12);

		//console.log(body);
		let url = getBenefitsApiUrl() + '/benefit'
		apiPost(url, body).then((resp) => {
			setMessage({text: 'Benefit issued.', type:'success'});
			setFormChanged(false);
			setEndDateDisabled(false);
			setBenefitAmount('');
			setBenefitAmountDisabled(false);
			setBenefit(blankForm);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		getPrograms();
		getAgencies();
	}, []);

	useEffect( () => {
		invalidInputStyle();

		if (props.getId && props.getId()) {
			let id = props.getId();
			if (id != benefit.benefitRecipientAccountIdentifier)
				setBenefit({...benefit, benefitRecipientAccountIdentifier:id});
			if (props.clearId)
				props.clearId();
			}
	});

	let [programPassedIn, setProgramPassedIn] = useState('');
	if (props.programUuid && !programPassedIn)
		setProgramPassedIn(props.programUuid);

	let [agencyPassedIn, setAgencyPassedIn] = useState('');
	if (props.agencyUuid && !agencyPassedIn)
		setAgencyPassedIn(props.agencyUuid);
	
	useEffect( () => {
		if (!benefit.programUuid && programPassedIn) {
			setTimeout( () => {
				//console.log('setting');
				document.getElementById('programUuid').value = programPassedIn;
				setBenefit({...benefitRef.current, programUuid:programPassedIn});
			}, 400);
		}
	}, [programPassedIn]);
	
	useEffect( () => {
		if (!benefit.agencyUuid && agencyPassedIn) {
			setTimeout( () => {
				document.getElementById('agencyUuid').value = agencyPassedIn;
				setBenefit({...benefitRef.current, agencyUuid:agencyPassedIn});
			}, 700);
		}
	}, [agencyPassedIn]);

	if (props.recipientIdentifier && !benefit.benefitRecipientAccountIdentifier){
		setBenefit({...benefit, benefitRecipientAccountIdentifier:props.recipientIdentifier});
	}

	return (
		<>
		<MessageTag message={message}/>
		<Processing show={showSpinner}/>
        <form onSubmit={handleIssue}>
			<div className="row">
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control custom-select" value={benefit.programUuid} onChange={handleFieldChange} required>
						{ selectOptions(programs) }
					</select>
				</div>
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="fundingId">Funding</label>
					<select id="fundingId" className="form-control custom-select" value={benefit.fundingId} onChange={handleFieldChange} required>
						{ selectOptions(fundingSources) }
					</select>
				</div>
				<div className={(agencyRequired() ? "required " : "") + "form-group col-12 col-sm-4"}>
					<label className="asterisk-label" htmlFor="agencyUuid">Agency</label>
					<select id="agencyUuid" className="form-control custom-select" value={benefit.agencyUuid} onChange={handleFieldChange} required={agencyRequired()}>
						{ selectOptions(agencies) }
					</select>
				</div>
			</div>

			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="benefitRecipientAccountIdentifier">Recipient Account ID</label>
					<input type="text" id="benefitRecipientAccountIdentifier" className="form-control" onChange={handleFieldChange} value={benefit.benefitRecipientAccountIdentifier} required maxLength={25}/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="benefitAuthorizationNumber">Authorization Number</label>
					<input type="text" id="benefitAuthorizationNumber" className="form-control" onChange={handleFieldChange} value={benefit.benefitAuthorizationNumber} required maxLength={20} readOnly={benefit.benefitTypeId == 2} pattern="\S*"/>
				</div>
			</div>

			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="benefitTypeId">Benefit Type</label>
					<select id="benefitTypeId" className="form-control custom-select" value={benefit.benefitTypeId} onChange={handleFieldChange} required>
						{ selectOptions(BenefitTypeArray) }
					</select>
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="incentiveUuid">Incentive</label>
					<select id="incentiveUuid" className="form-control custom-select" value={benefit.incentiveUuid} onChange={handleFieldChange} required={benefit.benefitTypeId == 2}>
						{ selectOptions(incentives) }
					</select>
				</div>
			</div>
			
			<div className="row">
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="benefitAvailableDate">Available Date</label>
					<br />
					<DatePicker value={benefit.benefitAvailableDate} onChange={(val)=>handleDayChange('benefitAvailableDate', val)} className={"form-control"} minDate={new Date()} maxDate={expirationDays.lastIssuanceDate ? expirationDays.lastIssuanceDate : maxDate} required format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="benefitExpirationDate">Expiration Date</label>
					<br />
					<DatePicker value={benefit.benefitExpirationDate} onChange={(val)=>handleDayChange('benefitExpirationDate', val)} className={"form-control"} minDate={new Date()} required format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD" disabled={endDateDisabled}/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="benefitAmount">Amount</label>
					<input type="number" id="benefitAmount" className="form-control text-right" onChange={handleFieldChange} value={benefit.benefitAmount} min="0.00" step="0.01" required maxLength={10} pattern="^[0-9\.]{0,10}$" disabled={benefitAmountDisabled}/>
				</div>
			</div>

			<div className="row">
			{ expirationDays.enableBenefitNote ? 
				<div className="form-group col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="note">Note</label>
					<input type="text" id="note" className="form-control" onChange={handleFieldChange} value={benefit.note} maxLength={100} />
				</div>
				 : null}

			{ expirationDays.benefitOptionTypeId ? 
				<div className="form-group required col-12 col-sm-4">
				<label className="asterisk-label" htmlFor="benefitOptionId">{benefitOptionType.name}</label>
				<select id="benefitOptionId" className="form-control custom-select" value={benefit.benefitOptionId} onChange={handleFieldChange} required>
					{ selectOptions(benefitOptionType.options,'optionId','descr') }
				</select>
				</div>
				 : null}
			</div>

			<button className="btn btn-primary" id="btnIssue" disabled={!formChanged}>Issue</button>
		</form>

		<AlertDialog settings={alertSettings}/>
		</>
	)
}
